import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import { unmountComponentAtNode } from "react-dom";
import {base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import Confetti from 'react-confetti';
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import {validateEmail} from '../utils/HelpfulFunction';


let alreadyCheckedForPrize = false;
class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: props.appStorage.getItem('userEmail') || props.appStorage.getItem('tempToken') || false,
            gameActive: false,
            tenantRules: {},
            selectedItem: 0,
            status: 0,
            width: 0,
            height: 0,
            currentResponseArray: [],
            currentGameId: null,
            locationChecking: true,
            loading: true,
            startConfetti: false,
            downloadingImage: false,
            downloadedFile: null,
            tierMessaging: [],
            otherInput: "",
            formQuestions: []
        };
        this.other_checkbox = null;

        this.other_checkbox_ref = element => {
            this.other_checkbox = element;
        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.handleGameMethods();

        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });

        this.formQuestionsRef = base.bindToState("formQuestions", {
            context: this,
            state: 'formQuestions',
            asArray: true
        });

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.scrollTo(0,0);
        this.props.loadPage();
    }

    componentWillUnmount() {
        base.removeBinding(this.gameActiveRef);
        base.removeBinding(this.currentGameKeyRef);
        base.removeBinding(this.statusRef);
        base.removeBinding(this.participantsRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.formQuestionsRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevState.status && !this.state.status) || (prevState.currentGameId !== this.state.currentGameId)){
            this.resetGame();
        }
        if(!prevState.gameActive && this.state.gameActive && !this.state.locationChecking) {
            this.logUserActivity(this.state.currentGameId);
            if(!alreadyCheckedForPrize){
                alreadyCheckedForPrize = true;
                this.sendImmediatePrize();
            }
        }
        if(this.state.gameActive && this.state.participants === "immediate" && !alreadyCheckedForPrize){
            alreadyCheckedForPrize = true;
            this.sendImmediatePrize();
        }
    }

    resetGame(){
        this.setState({
            startConfetti:false,
            gameEnded: false
        })
        alreadyCheckedForPrize = false;
        this.removeReactAlert();
    }

    handleGameMethods(){
        this.statusRef = base.listenTo('currentGame/status', {
            context: this,
            state: 'status',
            then(status){
                this.setState({
                    status: status
                });
            }
        })
        this.gameActiveRef = base.bindToState('currentGame/active', {
            context: this,
            state: 'gameActive'
        })
        this.participantsRef = base.bindToState('currentGame/participants', {
            context: this,
            state: 'participants'
        })
        this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
            context: this,
            then(key){
                this.setState({
                    currentGameId:key
                }, () => {
                    if(this.props.variables && this.props.variables.collectDistance && this.props.variables.latitude && this.props.variables.acceptableDistance){
                        this.checkUsersLocation();
                    } else {
                        this.logUserActivity(key);
                        this.registerSeasonLongPlayers();
                        this.setState({
                            locationChecking: false,
                            loading: false
                        })
                    }
                })
            }
        });
    }

    sendImmediatePrize(){
        const stringConstants = this.props.stringConstants || {};
        const variables = this.props.variables || {};
        const userResponse = {};
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(userEmail && userEmail.indexOf('@') === -1){
            base64EncodedEmail = userEmail
        }
        const vm = this;
        userResponse['uid'] = base64EncodedEmail;
        userResponse['tag'] = process.env.REACT_APP_FIREBASE_PROJECT_ID;
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/sendImmediatePrizeWithOutAnswer`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/sendImmediatePrizeWithOutAnswer`;
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userResponse),
        }).then(res => {
            vm.setState({
                loading: false
            })
            Promise.resolve(res.json()).then(function (value) {
                if(value.error){
                    confirmAlert({
                        title: stringConstants.ERRORTEXT,
                        variables: variables,
                        message: stringConstants.SOMEERROROCCURED,
                        buttons: [
                            {
                                label: stringConstants.OKTEXT
                            }
                        ]
                    });
                } else {
                    if(value.prizeWon){
                        vm.showGameOverMessage(true)
                    } else {
                        vm.showGameOverMessage(false)
                    }
                }
            })
        })
    }

    showGameOverMessage(gotWinningMessage){
        const tenantVariables = this.props.variables || {};
        const stringConstants = this.props.stringConstants || {};
        let headerHeader;
        let headerMessage;
        let messageImage = "";
        if(gotWinningMessage === true){
            headerHeader = tenantVariables.winningHeader || "You Win!";
            headerMessage = tenantVariables.winningMessage;
            messageImage = tenantVariables.winningMessageImage;
            this.setState({
                startConfetti: true
            })
        } else {
            headerHeader = tenantVariables.losingHeader || "No prize won";
            headerMessage = tenantVariables.losingMessage;
        }
        confirmAlert({
            variables: tenantVariables,
            messageImage:messageImage,
            title: headerHeader,
            message: headerMessage,
            buttons: [
                {
                    label: stringConstants.OKTEXT,
                    onClick: () => {
                        this.setState({startConfetti:false})
                    }
                }
            ]
        })
    }

    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    registerSeasonLongPlayers(){
        if(this.state.userEmail) {
            let base64EncodedEmail = btoa(this.state.userEmail);
            if (!validateEmail(this.state.userEmail)) {
                base64EncodedEmail = this.state.userEmail;
            }
            base.post(`campaignRegistrants/${base64EncodedEmail}` , {
                data: {uid: base64EncodedEmail, timeStamp: Date.now()},
                then(err){
                    if(!err){
                        console.log("SEASON REGISTERED")
                    } else {
                        console.log("ERROR: ", err)
                    }
                }
            })
        }
    }

    removeReactAlert(){
        document.body.classList.remove('react-confirm-alert-body-element');
        const target = document.getElementById('react-confirm-alert');
        if(target){
            unmountComponentAtNode(target)
            target.parentNode.removeChild(target);
        }
        const svg = document.getElementById('react-confirm-alert-firm-svg');
        if(svg){
            svg.parentNode.removeChild(svg)
            document.body.children[0].classList.remove('react-confirm-alert-blur');
        }
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    changeMilesToMeters(milesToConvert){
        return milesToConvert*1609.344;
    }

    getLocation(){
        const stringConstants = this.props.stringConstants;
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject(stringConstants.GEOLOCATIONNOTSUPPORTED);
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                            latitude: toCheckLatitude,
                            longitude: toCheckLongitude,
                        }))
                    },
                    (err) => {
                        if(err.message === "User denied Geolocation"){
                            reject(stringConstants.NEEDBROWSERPERMISSION);
                        } else {
                            console.log(err.message);
                            reject(stringConstants.UNKNOWNERROROCCURED);
                        }
                    }
                );
            }
        })
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            startConfetti: false
        });
    }

    toggleRules() {
        this.setState({
            modalRules: !this.state.modalRules,
        });
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport,
        });
    }

    getLocationPermission(){
        const stringConstants = this.props.stringConstants || {};
        const locationErrorTitle = stringConstants.LOCATIONERRORHEADER;
        this.getLocation().then(distance_meters => {
            this.props.appStorage.setItem('locationPermissions', "true");
            const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
            if(!this.props.variables.canPlayOutside && distance_meters <= allowed_distance){
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
                this.registerSeasonLongPlayers()
                this.logUserActivity(this.state.currentGameId);
            } else if(this.props.variables.canPlayOutside && distance_meters > allowed_distance) {
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
                this.registerSeasonLongPlayers()
                this.logUserActivity(this.state.currentGameId);
            } else {
                this.setState({
                    modal:false,
                    loading:false
                });
                let locationErrorMessage = "Too far from game area to participate!";
                if(this.props.variables.canPlayOutside){
                    locationErrorMessage = "Too close game area to participate!";
                }
                if(this.props.variables.notAcceptableLocationMessage){
                    locationErrorMessage = this.props.variables.notAcceptableLocationMessage;
                }
                let notAcceptableLocationHeader = locationErrorTitle;
                if(this.props.variables.notAcceptableLocationHeader){
                    notAcceptableLocationHeader = this.props.variables.notAcceptableLocationHeader;
                }
                confirmAlert({
                    title: notAcceptableLocationHeader,
                    variables: this.props.variables,
                    message: locationErrorMessage,
                    buttons: [
                        {
                            label: stringConstants.RETRYTEXT,
                            onClick: () => {
                                this.checkUsersLocation()
                            }
                        }
                    ]
                });
            }
        }, error => {
            console.log(error);
            this.setState({
                modal:false,
                loading:false
            });
            this.props.appStorage.setItem('locationPermissions', "false");
            if(typeof error != "string"){
                error = error.message
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: stringConstants.RETRYTEXT,
                        onClick: () => {
                            this.checkUsersLocation()
                        }
                    }
                ]
            });
        })
    }

    checkUsersLocation(){
        const stringConstants = this.props.stringConstants || {};
        const variables = this.props.variables;
        const locationPermissions = this.props.appStorage.getItem('locationPermissions');
        if(!this.props.variables.collectDistance) {
            this.setState({locationChecking: false})
        } else if(locationPermissions === "false" || !locationPermissions){
            this.setState({loading:false})
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: stringConstants.YESCONFIRMBUTTONTEXT,
                        onClick: () => {
                            this.setState({
                                loading: true,
                            });
                            this.getLocationPermission()
                        }
                    }
                ],
            })
        } else {
            this.setState({
                loading: true,
            });
            this.getLocationPermission()
        }
    }

    logout(){
        this.props.appStorage.removeItem('userEmail');
        this.props.appStorage.removeItem('verifiedAge');
        this.props.appStorage.removeItem('birthday');
        this.props.appStorage.removeItem('locationPermissions');
        this.props.appStorage.removeItem('id_token');
        this.setState({
            userEmail:false
        })
    }

    logUserActivity(gameId){
        if(this.state.userEmail){
            let base64EncodedEmail = btoa(this.state.userEmail);
            if(!validateEmail(this.state.userEmail)){
                base64EncodedEmail = this.state.userEmail;
            }
            if(gameId && this.state.gameActive && this.state.status !== 2){
                base.post(`userGameHistory/${base64EncodedEmail}/${gameId}` , {
                    data:gameId,
                    then(err){
                        if(!err){
                            console.log("user game logged!");
                        } else {
                            console.log("ERROR")
                            console.log(err)
                        }
                    }
                })
            }
        }
    }

    renderHoldingScreen(){
        const tenantVariables = this.props.variables || {};
        const holdingHeader = tenantVariables.holdingHeader || "The next game will start shortly";
        const holdingBody = tenantVariables.holdingBody || "";
        const logOutButtonColor = tenantVariables.logOutButtonColor || "#fff";
        return(
            <>
                <div className="hero-text-container">
                  <img src={tenantVariables.frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <p style={{color:logOutButtonColor}}>
                    <span className="emphasizedText">{holdingHeader}</span>
                    <br/>
                    <span style={{color:logOutButtonColor}} dangerouslySetInnerHTML={{ __html:holdingBody}}/>
                </p>
            </>
        )
    }

    renderPlayingScreen(){
        const tenantVariables = this.props.variables || {};
        const playingHeader = tenantVariables.playingHeader || "Status: Registered";
        const playingBody = tenantVariables.playingBody || "";
        const logOutButtonColor = tenantVariables.logOutButtonColor || "#fff";
        const primaryColor = tenantVariables.primaryColor || "black";
        const secondaryColor = tenantVariables.secondaryColor || "white";
        return(
            <>
                <div className="hero-text-container">
                    <img src={tenantVariables.frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <p style={{color:logOutButtonColor}}>
                    <span className="emphasizedText" style={{padding: 0}}>{playingHeader}</span>
                    <br/>
                    <span style={{color:logOutButtonColor, fontSize: "1.5rem"}} dangerouslySetInnerHTML={{ __html:playingBody}}/>
                </p>
                <div className="container-out" id="more-info-form" style={{marginTop: 10, display: tenantVariables.formHeaderText === "" || !tenantVariables.formHeaderText ? 'none' : 'block'}}>
                    <div className="question-box question-form" style={{padding: '15px'}}>
                        <h4 style={{fontWeight: 700, color: "black"}}>{tenantVariables.formHeaderText}</h4>
                        <form onSubmit={this.handleSubmit} id="user-values-form">
                            <div style={{color:"black", textAlign: "left"}}>
                                {
                                    this.state.formQuestions.map(function(item, i){
                                        return <div className="checkbox" style={{padding: '5px'}}>
                                            <label className="want_emails"><input type="checkbox" ref={item.id}/> {item.text}</label>
                                        </div>
                                    })
                                }
                                {tenantVariables.otherResponse &&
                                    <>
                                        <div className="checkbox" style={{padding: '5px'}}>
                                            <label className="want_emails">
                                                <input type="checkbox" checked={this.state.otherCheck} onChange={this.handleChange} id="otherCheck" name="otherCheck"/> Other
                                            </label>
                                        </div>
                                        {this.state.otherCheck &&
                                            <div className="input-group">
                                                <input id="otherInput" name="otherInput" type="text" className="form-control" onChange={this.handleChange} placeholder="Other Products"/>
                                            </div>
                                        }
                                    </>
                                }
                            </div>

                            <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>SUBMIT</strong></button>
                        </form>
                    </div>
                </div>
            </>
        )
    }

    async handleSubmit(event){
        event.preventDefault();
        const formQuestions = this.state.formQuestions || [];
        const stringConstants = this.props.stringConstants || {};
        const tenantVariables = this.props.variables || {};
        const dataObject = {};
        let somethingChecked = false;
        for(const i in formQuestions){
            let userResponse = false;
            if(this.refs[formQuestions[i].id].checked){
                somethingChecked = true;
                userResponse = true;
            }
            dataObject[formQuestions[i].id] = userResponse;
        }
        const other_checkbox = this.state.otherCheck || false;
        const otherInput = this.state.otherInput.trim();
        if(!somethingChecked) {
            confirmAlert({
                variables: tenantVariables,
                title: stringConstants.MISSINGINFOTEXT,
                message: "To submit you need to check at least one box",
                buttons: [
                    {
                        label: stringConstants.OKTEXT,
                    }
                ]
            });
            return
        } else if(other_checkbox && !otherInput){
            confirmAlert({
                variables: tenantVariables,
                title: stringConstants.MISSINGINFOTEXT,
                message: "If you check other you need to fill in the other input",
                buttons: [
                    {
                        label: stringConstants.OKTEXT,
                    }
                ]
            });
            return;
        }
        const base64EncodedEmail = btoa(this.state.userEmail);
        if(!base64EncodedEmail){
            return
        }

        await base.update(`users/`+ base64EncodedEmail, {
            data: {
                other_checkbox: other_checkbox|| "",
                otherInput: otherInput || ""
            },
            then(err){
                if(err){
                    confirmAlert({
                        variables: tenantVariables,
                        title: stringConstants.ERRORTEXT,
                        message: err.message,
                        buttons: [
                            {
                                label: stringConstants.OKTEXT,
                            }
                        ]
                    })
                } else {
                    base.update(`users/`+ base64EncodedEmail + `/form_responses` , {
                        data: dataObject,
                        then(err){
                            if(!err){
                                document.getElementById('more-info-form').style.display = "none";
                            } else {
                                console.log(err);
                                confirmAlert({
                                    variables: tenantVariables,
                                    title: stringConstants.ERRORTEXT,
                                    message: err.message,
                                    buttons: [
                                        {
                                            label: stringConstants.OKTEXT,
                                        }
                                    ]
                                })
                            }
                        }
                    });
                }
            }
        })
    }

    render() {
        const isRaidersFord = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'raidersford';
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'sendprizesdev'
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const tenantVariables = this.props.variables || {};
        const stringConstants = this.props.stringConstants || {};
        const tenantRules = this.state.tenantRules || {};
        const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
        const primaryColor = tenantVariables.primaryColor || "black";
        const secondaryColor = tenantVariables.secondaryColor || "white";
        const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
        let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
        let rulesPopUpText = tenantRules.rulesPopUpText;
        let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
        const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
        const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
        const link = tenantRules.rulesAndRegsLink;
        const rulesShowInApp = tenantRules.rulesShowInApp || isMlbApp || false;
        if(rulesShowInApp) {
            if(!rulesShowInAppPopUpText && rulesPopUpText){
                rulesShowInAppPopUpText = rulesPopUpText;
            }
            if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
                rulesShowInAppPopUpHeader = rulesPopUpHeader;
            }
        }
        if(!this.state.userEmail){
            let nextUrl = 'login';
            if(isRaidersFord || isDev){
                nextUrl = "ford/login";
            }
            return <Redirect to={nextUrl} />
        } else if(!tenantVariables.doNotCollectEmail && !isMlbApp && !validateEmail(this.state.userEmail)){
            this.logout();
        } else if((tenantVariables.doNotCollectEmail || isMlbApp) && validateEmail(this.state.userEmail)){
            this.logout();
        }
        if (this.state.loading === true) {
            return (
                <Loading primaryColor={tenantVariables.primaryColor} backgroundImage={tenantVariables.backgroundImage}/>
            )
        }
        let renderMainScreen = "";
        if(this.state.gameActive && !this.state.locationChecking){
            renderMainScreen = this.renderPlayingScreen()
        } else {
            renderMainScreen = this.renderHoldingScreen()
        }
        let maxWidthAllowed = 300;
        const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
        if(bodyWidth > 0 && bodyWidth < 300){
            maxWidthAllowed = bodyWidth;
        }
        return(
            <div
                className="flex-container-home"
                style={{
                    backgroundImage: `url(${tenantVariables.backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundAttachment: 'fixed'
                }}
            >
              {this.state.startConfetti &&
                <Confetti width={this.state.width} height={this.state.height} style={{zIndex: 1 }}/>
              }
              <div className="flex-content-container-home">
                  <div className="intro-container-home">
                      <div className="grid-wrapper">
                          <div className="flex-header-home" style={{marginTop:10, width: "100vw"}}>
                              <div style={{display: isMlbApp? "block":"none", textAlign: "left"}}>
                                  <button className="btn btn-logout" onClick={() => { this.toggleSupport() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.SUPPORTTEXT}</button>
                              </div>
                              <div style={{visibility: rulesShowInApp? "visible":"hidden", marginLeft: isMlbApp && "auto"}}>
                                  <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                              </div>
                              <div style={{display: (isMlbApp || this.props.passedEmail) && "none", marginRight:10}}>
                                  <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.LOGOUTTEXT}</button>
                              </div>
                          </div>
                          {renderMainScreen}
                      </div>
                  </div>
              </div>
              <Modal isOpen={this.state.winnerModal} id="myOtherModal">
                  <center style={{maxWidth:maxWidthAllowed, marginTop:10, marginBottom: 10}}>
                      <span className="unselectable">{stringConstants.HOLDYOURFINGERDOWNTODOWNLOADTEXT}<br/>👇👇👇👇👇👇</span>
                      <div className="unselectable" style={{height:10, width: "100%"}}/>
                      <center id="downloadModalId"/>
                  </center>
              </Modal>
              <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesShowInAppPopUpHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
              <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
          </div>
        )
    }
}

export default Main_App;
