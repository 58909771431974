import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import RedeemedViewer from "./components/main_screens/RedeemedViewer";
import {base} from './base';
import Analytics from 'analytics';
import hubSpotPlugin from '@analytics/hubspot';
import FallbackLocalStorage from "fallback-local-storage";
import RulesViewer from "./components/main_screens/RulesViewer";
let appStorage;

let analytics;
if(process.env.REACT_APP_HUBSPOT_ID){
    analytics = Analytics({
        app: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        plugins: [
            hubSpotPlugin({
                portalId: process.env.REACT_APP_HUBSPOT_ID
            })
        ]
    })
}

function logPageView(path) {
    if(analytics){
        analytics.page();
    }
}

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    let redirectPath = '/login';
    if(process.env.REACT_APP_FIREBASE_PROJECT_ID === 'raidersford'){
        redirectPath = '/ford/login'
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: redirectPath, state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        let userEmail;
        try {
            userEmail = appStorage.getItem('userEmail') || false;
        } catch (e) {
            userEmail = false
        }
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false
        }
    }

    componentDidMount() {
        this.getTenantVariables();
        this.loadFont();
    }

    loadFont(){
        const fontEnv = process.env.REACT_APP_FONT_ENV;

        if (fontEnv === 'Ford') {
            import('../src/styles/css/font.ford.css');
        } else {
            import('../src/styles/css/font.env.css');
        }
    }

    setCurrentUser() {
        const userEmail = appStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    getTenantVariables(){
        let language = "english"
        let splitWindowLocation = window.location.pathname.split('/') || ""
        if(window.location.pathname.split('/').length > 2){
            language = splitWindowLocation[2]
        } else if(window.location.pathname.split('/').length > 1){
            language = splitWindowLocation[1]
        }
        this.loadLanguage(language)
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then(variables){
                document.title = variables.pageTitle || "Game";
                if(variables.faviconImage){
                    const faviconElement = document.getElementById("favicon-id");
                    faviconElement.href = variables.faviconImage;
                }
                this.setState({
                    authenticated: false,
                    variables: variables,
                    loading: false,
                    language: language
                })
                this.setUpStorage(variables);
            }
        });
    }

    setUpStorage(variables){
        const keepFansLoggedIn = variables.keepFansLoggedIn || false;
        if (FallbackLocalStorage.getStorage().includes("sessionStorage") && !keepFansLoggedIn) {
            // Here we don't have any problems
            // with writing to `window.localStorage`
            appStorage = globalThis.sessionStorage;
        } else if(FallbackLocalStorage.getStorage().includes("localStorage") && keepFansLoggedIn) {
            appStorage = globalThis.localStorage;
        } else {
            // Looks like we have some troubles.
            // Browser has disable `window.localStorage` support.
            // Or browser is in `Private Mode`
            // which disables localStorage completely.
            appStorage = new FallbackLocalStorage();
        }
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    setPassedEmail(isSet=true){
      this.setState({
          passedEmail: isSet
      })
    }

    async loadLanguage(language){
        let languagesConstants;
        if(language){
            try {
                languagesConstants = await import("./constants/languages/"+language+".js");
            } catch(e) {
                language = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
                languagesConstants = await import("./constants/languages/"+language+".js");
            }
        } else {
            language = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
            languagesConstants = await import("./constants/languages/"+language+".js");
        }
        this.setState({
            languageConstants: languagesConstants
        })
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age;
        try {
            user_age = appStorage.getItem('verifiedAge')
        } catch (e) {
            user_age = false;
        }
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

    render() {
        const isRaidersFord = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'raidersford';
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'sendprizesdev'
        let loginPath = '/login';
        let homePath = '/';
        if(isRaidersFord || isDev){
            loginPath = '/ford/login'
            homePath = '/ford'
        }
        if (this.state.loading === true) {
            return (
                <Loading backgroundImage={this.state.variables.backgroundImage} primaryColor={this.state.variables.primaryColor || "#000"}/>
            )
        }
        return (
            <BrowserRouter>
              <Switch>
                <Route
                    strict
                    path={loginPath}
                    render={(props) => {
                        return <Login appStorage={appStorage} authenticated={this.state.authenticated} loadPage={() => logPageView()} stringConstants={this.state.languageConstants} variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} checkForAgeGate={this.checkForAgeGate} setCurrentUser={this.setCurrentUser} {...props} />
                    }}
                />

                  <AuthenticatedRoute
                      exact
                      path={homePath}
                      authenticated={this.state.authenticated}
                      stringConstants={this.state.languageConstants}
                      variables={this.state.variables}
                      passedEmail={this.state.passedEmail}
                      loadPage={() => logPageView()}
                      checkForAgeGate={this.checkForAgeGate}
                      appStorage={appStorage}
                      component={MainApp} />

                <Route
                    strict
                    path="/age_gate"
                    render={(props) => {
                        return <AgeVerification appStorage={appStorage} authenticated={this.state.authenticated} loadPage={() => logPageView()} stringConstants={this.state.languageConstants} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />

                  <Route
                      exact
                      path="/redeemviewer"
                      render={(props) => {
                          return <RedeemedViewer appStorage={appStorage} loadPage={() => logPageView()} stringConstants={this.state.languageConstants} tenantVariables={this.state.variables} {...props} />
                      }}
                  />

                  {homePath === "/ford" &&
                      <Route
                          exact
                          path="/ford/rules"
                          render={(props) => {
                              return <RulesViewer loadPage={() => logPageView()} tenantVariables={this.state.variables} {...props} />
                          }}
                      />
                  }

                <Redirect to={loginPath}/>
              </Switch>
            </BrowserRouter>
        )
    }
}

export default App;
